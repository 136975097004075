import { useImageResolver } from "api/hooks/useImageResolver";
import type { ImageDto } from "api/types";
import bgPlaceholder from "assets/images/image-placeholder.png";
import { ContextMenu, type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Headline4, Subtitle2 } from "components/Text/Text";
import type React from "react";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface EntityDetailsCardProps {
  title: string;
  description: string;
  image?: ImageDto;
  actions?: ContextMenuAction[];
  children?: ReactNode;
  renderLabels?: () => ReactNode;
  renderBody?: () => ReactNode;
  renderCtaButton: () => ReactNode;
  renderFooter?: () => ReactNode;
}

export function EntityDetailsCard({
  title,
  description,
  image,
  actions,
  renderLabels,
  renderBody,
  renderCtaButton,
  renderFooter,
}: EntityDetailsCardProps): React.ReactNode {
  const resolveImage = useImageResolver();
  const { t } = useTranslation();

  return (
    <div className="flex w-full flex-col rounded-lg bg-white">
      <div
        className="relative h-64 rounded-t-lg bg-grey-light bg-cover bg-center"
        style={{
          backgroundImage: `url(${image ? resolveImage(image, "large") : bgPlaceholder})`,
        }}
      >
        {renderLabels && <div className="absolute left-3 top-3 flex gap-2">{renderLabels()}</div>}
      </div>
      <div className="flex flex-col gap-5 p-4">
        <div className="flex w-full items-center justify-between">
          <Headline4 className="break-all leading-none" as="h2">
            {title}
          </Headline4>

          <div className="flex items-center gap-4">
            <div className="flex flex-wrap items-center justify-between gap-1">{renderCtaButton()}</div>
            {actions && <ContextMenu actions={actions} />}
          </div>
        </div>

        {renderBody && renderBody()}

        <div className="flex flex-col gap-1">
          <Subtitle2>{t("model.document.root.description")}</Subtitle2>
          <LinkFormatter>{description}</LinkFormatter>
        </div>

        {renderFooter && renderFooter()}
      </div>
    </div>
  );
}
