import type { BookableAssetDto } from "api/types";
import { Button } from "components/Button/Button";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { BookableAssetsGridView } from "modules/bookings/components/BookableAssetsGridView";
import { canCreateBookableAssets } from "modules/bookings/permissions";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

export interface LayoutProps {
  assets: BookableAssetDto[];
  hasMoreAssets: boolean;
  isFetchingMoreAssets: boolean;
  fetchMoreAssets: () => void;
}

export function Layout({ assets, hasMoreAssets, isFetchingMoreAssets, fetchMoreAssets }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();
  const hasPermission = usePermission();

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.bookings.list-assets.title")}
      subTitle={t("page.bookings.list-assets.subtitle")}
      actions={
        hasPermission(canCreateBookableAssets) ? (
          <Button type="link" href={routes.bookings.createAsset({ slug })} styling="primary">
            {t("page.bookings.list-assets.actions.new")}
          </Button>
        ) : !hasPermission((x) => x.assets.canViewSchedule) ? (
          <Button type="link" href={`${routes.calendar.list({ slug })}?tab=reservations`} styling="primary">
            {t("page.bookings.list-assets.actions.see-reservations")}
          </Button>
        ) : null
      }
    >
      <BookableAssetsGridView
        onLoadMore={fetchMoreAssets}
        isLoading={isFetchingMoreAssets}
        hasMore={hasMoreAssets}
        {...{ assets }}
      />
    </DocumentPaper>
  );
}
