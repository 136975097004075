import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { QuickReplyRepairRequestCommentDto, UserDto } from "api/types";
import { Button } from "components/Button/Button";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Gallery } from "components/Gallery/Gallery";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Pdf } from "components/Pdf/Pdf";
import { Capture2, Subtitle2 } from "components/Text/Text";
import { Tooltip } from "components/Tooltip/Tooltip";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { parseISO } from "date-fns";
import { motion } from "framer-motion";
import { stripTime } from "helpers/date";
import { useBool } from "hooks/useBool";
import { groupBy, sortBy } from "lodash-es";
import { QUERY_KEYS } from "query-keys";
import { Lock } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface TicketActivityWrapperProps {
  postedAt: string;
  author: UserDto;
}

export function TicketActivityWrapper({
  postedAt,
  author,
  children,
}: React.PropsWithChildren<TicketActivityWrapperProps>): React.ReactNode {
  return (
    <article className="my-2 flex rounded-3px p-2 pb-0">
      <div className="mr-2 size-8">
        <UserAvatar img={author.avatar} isUserDeleted={!!author.deletedAt} />
      </div>
      <div className="grid flex-1 grid-cols-1 pt-0.5">
        <div className="flex items-start">
          <div className="flex flex-wrap items-center">
            <Subtitle2 className="mr-2 truncate text-black">{author.fullName}</Subtitle2>
            <Capture2 className="text-grey">
              <FormattedDate date={postedAt} format="time" />
            </Capture2>
          </div>
        </div>

        <p className="my-1 whitespace-pre-line rounded-3px border border-grey-lighter p-2 italic">{children}</p>
      </div>
    </article>
  );
}

interface TicketCommentProps {
  token: string;
  languageId: string;
  isReadonly?: boolean;
  comment: QuickReplyRepairRequestCommentDto;
  isOwn: boolean;
  onEdit?: (comment: QuickReplyRepairRequestCommentDto) => void;
}

export function TicketComment({ token, languageId, comment, isOwn, onEdit }: TicketCommentProps): React.ReactNode {
  const { t } = useTranslation();

  // User should only be allowed to modify his own post messages or repair request notes
  const isNote = comment.internal;
  const api = useApi();
  const showFlashToast = useFlashToast();

  const [hideTranslation, hideTranslationHandlers] = useBool(true);
  const translation = useQuery({
    queryKey: QUERY_KEYS.QUICK_REPLY_REPAIR_ACTIVITY_TRANSLATION(token, comment.id),
    queryFn: () => api.getQuickReplyRepairRequestActivitiesTranslationsDetailsV1(token, comment.id, languageId),
    retry: false,
    enabled: false,
  });

  const hasTranslation = translation.data && !hideTranslation;
  const translateButton =
    comment.content &&
    comment.languageIsoCode !== languageId &&
    !isOwn &&
    !comment.deletedAt &&
    !isNote &&
    !comment.isAutoReply ? (
      <Button
        styling="ghostPrimary"
        isLoading={translation.isLoading}
        onClick={async () => {
          hideTranslationHandlers.toggle();

          if (!translation.data) {
            try {
              await translation.refetch();
            } catch (error) {
              showFlashToast({ type: "error", title: t("page.tickets.details.activities.comment.translate.failed") });
            }
          }
        }}
      >
        {hasTranslation
          ? t("page.tickets.details.activities.comment.translate.original")
          : t("page.tickets.details.activities.comment.translate")}
      </Button>
    ) : null;

  const content =
    comment.deletedAt && !comment.content
      ? isNote
        ? t("component.community-post-comments.note-deleted")
        : t("component.community-post-comments.comment-deleted")
      : hasTranslation
        ? translation.data.data.content
        : comment.content;

  return (
    <motion.article
      className={twJoin(
        "my-2 flex rounded-3px p-2 pb-0",
        comment.deletedAt && "text-grey-light",
        isNote ? "mr-2 bg-yellow-lightest pb-2" : "first:pt-0",
      )}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, height: 0, marginBottom: "0px" }}
      data-testid="comment-root"
    >
      <div className={twJoin("mr-2 size-8", comment.deletedAt ? "opacity-40" : undefined)}>
        <UserAvatar img={comment.author.avatar} isUserDeleted={!!comment.author.deletedAt} />
      </div>
      <div className="grid flex-1 grid-cols-1 pt-0.5">
        <div className={twJoin("flex items-start", comment.deletedAt ? "text-grey-light" : undefined)}>
          <div className="flex flex-wrap items-center">
            <Subtitle2 className={twJoin("mr-2 truncate", comment.deletedAt ? "text-grey-light" : "text-black")}>
              {comment.author.fullName}
            </Subtitle2>

            <Capture2 className="text-grey">
              <FormattedDate date={comment.postedAt} format="time" />
            </Capture2>

            {comment.updatedAt && (
              <Tooltip tooltip={<FormattedDate date={comment.updatedAt} format="datetime" />}>
                <Capture2
                  className={
                    "relative ml-1 cursor-default italic text-grey after:absolute after:inset-x-0 after:bottom-0 after:hidden after:h-px after:w-full after:bg-grey after:content-[''] hover:after:block"
                  }
                >
                  ({t("model.repair-request.comment-edited")})
                </Capture2>
              </Tooltip>
            )}
          </div>

          {isNote && (
            <span className="ml-auto mr-1 flex h-6 items-center text-sm text-yellow-darkest md:mr-3">
              <Lock className="mr-1 block" size={14} />
              <Capture2 className="hidden sm:block">{t("model.repair-request.comment-note")}</Capture2>
            </span>
          )}
        </div>

        <div className="flex w-full flex-col items-start gap-2">
          {content && (
            <p
              className={twJoin(
                "max-w-prose whitespace-pre-line",
                comment.deletedAt && comment.content && "line-through",
              )}
              data-testid="comment-content"
            >
              <LinkFormatter>{content}</LinkFormatter>
              {translateButton}
            </p>
          )}

          {/* Attachments */}
          {comment.images.length > 0 && (
            <div className="max-w-full">
              <Gallery images={comment.images} isDeleted={!!comment.deletedAt} />
            </div>
          )}
          {comment.documents.length > 0 && (
            <div className="max-w-full" data-testid="comment-attachment-document">
              <Pdf
                fileName={comment.documents[0].fileName}
                onClick={() => window.open(comment.documents[0].url, "_blank")}
              />
            </div>
          )}

          {isNote && isOwn && onEdit && (
            <div className="max-w-full">
              <Button styling="ghostSecondary" size="sm" onClick={() => onEdit(comment)}>
                {t("common.action.edit")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </motion.article>
  );
}

export function groupActivities<T extends { postedAt: string }>(activities: T[]): [string, T[]][] {
  const sortedComments = sortBy(activities, (x) => parseISO(x.postedAt).valueOf());
  const groupedByDay = Object.entries(groupBy(sortedComments, (x) => stripTime(x.postedAt).valueOf()));

  return sortBy(groupedByDay, ([date]) => Number(date));
}
