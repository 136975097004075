import checkIcon from "assets/icons/check.svg";
import { Icon } from "components/Icon/Icon";
import { twResolve } from "helpers/tw-resolve";
import type { ChangeEvent } from "react";
import React from "react";

export interface CheckboxProps {
  id?: string;
  name?: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  shape?: "rounded" | "square";
  color?: "blue" | "green";
  disabled?: boolean;
  className?: string;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { onChange, shape = "square", color = "blue", className, ...props },
  ref,
) {
  return (
    <div className="relative inline-flex size-5 shrink-0">
      <input
        {...props}
        id={props.id || props.name}
        ref={ref}
        className={twResolve("peer absolute z-10 size-full opacity-0 disabled:pointer-events-none", className)}
        onChange={onChange}
        type="checkbox"
      />
      <div
        className={twResolve(
          "flex size-full cursor-pointer items-center justify-center border border-grey bg-white text-white peer-focus-visible:ring-1 peer-focus-visible:ring-offset-1 peer-disabled:border-grey-light peer-disabled:bg-grey-lightest peer-checked-disabled:border-grey-lighter peer-checked-disabled:bg-grey-lighter peer-checked-all-child:block",
          color === "blue"
            ? "peer-checked:border-aop-basic-blue peer-checked:bg-aop-basic-blue peer-hover:bg-grey-lightest peer-hover:ring-grey-darkest peer-focus-visible:ring-grey-darkest peer-checked-hover:border-aop-basic-blue-dark peer-checked-hover:bg-aop-basic-blue-dark peer-checked-focus-visible:ring-aop-basic-blue-dark"
            : "border-green peer-checked:bg-green peer-hover:bg-green-lightest peer-hover:ring-green-darkest peer-focus-visible:ring-green-darkest peer-checked-hover:border-green-darker peer-checked-hover:bg-green-darker peer-checked-focus-visible:ring-green-darkest",
          shape === "rounded" ? "rounded-full" : "rounded-sm",
        )}
      >
        <Icon name={checkIcon} className="absolute hidden" />
      </div>
    </div>
  );
});
