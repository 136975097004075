import { useMutation } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { AIButton } from "components/Button/AIButton";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormTextArea } from "components/Form/FormTextArea";
import { Modal } from "components/Modal/Modal";
import { Headline4, Subtitle2 } from "components/Text/Text";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface TicketCopilotProps {
  isOpen: boolean;
  onClose: () => void;
  onGenerate: (message: string) => void;
  ticketId: string;
  defaultInput?: FormValues;
}

interface FormValues {
  input: string;
}

export function TicketCopilot({
  isOpen,
  onClose,
  onGenerate,
  ticketId,
  defaultInput,
}: TicketCopilotProps): React.ReactNode {
  const { t } = useTranslation();
  const form = useForm<FormValues>({ defaultValues: defaultInput });
  const api = useApi();
  const showFlashToast = useFlashToast();

  const ticketSuggestions = useMutation({
    mutationFn: (query: { additionalInfo: string } | undefined) =>
      api.postTicketsSuggestionV1(ticketId, query).then((res) => res.data),

    onError: () => {
      showFlashToast({ type: "error", title: t("page.tickets.details.copilot.generate.error") });
    },
  });

  async function handleSubmit() {
    const input = form.getValues("input");

    const suggestion = await ticketSuggestions.mutateAsync(input ? { additionalInfo: input } : undefined);
    onGenerate(suggestion.response);

    form.reset();
    onClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldReturnFocusAfterClose={false}
      shouldCloseOnOverlayClick={false}
    >
      <div className="flex flex-col gap-4 p-4 pb-0">
        <Headline4>{t("page.tickets.details.copilot.modal.title")}</Headline4>
        <Subtitle2 className="font-normal">{t("page.tickets.details.copilot.modal.description")}</Subtitle2>
        <Form formMethods={form} onSubmit={handleSubmit}>
          <FormContent maxWidth="xl">
            <FormField label={t("page.tickets.details.copilot.modal.text-area.label")} htmlFor="input">
              <FormTextArea
                id="input"
                name="input"
                placeholder={t("page.tickets.details.copilot.modal.text-area.placeholder")}
              />
            </FormField>
            <div className="flex w-full justify-center">
              <AIButton type="submit" disabled={ticketSuggestions.isPending}>
                {t("page.tickets.details.copilot.modal.button")}
              </AIButton>
            </div>
          </FormContent>
        </Form>
      </div>
    </Modal>
  );
}
