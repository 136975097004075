import { useInfiniteQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { usePermission } from "hooks/usePermission";
import { QUERY_KEYS } from "query-keys";
import { useMemo } from "react";
import type { ApiQueryParams } from "types/api-types";

import type { LayoutProps } from "./Layout";

// 12 items per load to prevent orphan issue in the UI
// as it can either be 1, 2, 3 or 4 items per row
const AMOUNT_ITEMS_TO_LOAD = 12;

interface LoaderProps {
  children: (props: LayoutProps) => React.ReactNode;
}

export function Loader({ children }: LoaderProps): React.ReactNode {
  const projectId = useProjectId();
  const api = useApi();
  const hasPermission = usePermission();

  const assetsQuery: ApiQueryParams<"getBookableAssetsV1"> = {
    HideUnpublished: !hasPermission((x) => x.assets.canManageAll || x.assets.canManageOwn),
  };

  const {
    data: assetsData,
    isPending: isPendingAssets,
    error: assetsError,
    hasNextPage: hasMoreAssets,
    fetchNextPage: fetchMoreAssets,
    isFetchingNextPage: isFetchingMoreAssets,
  } = useInfiniteQuery({
    queryKey: QUERY_KEYS.BOOKINGS_ASSETS_INFINITE(projectId, assetsQuery),
    queryFn: ({ pageParam = 0 }) =>
      api
        .getBookableAssetsV1({
          ...assetsQuery,
          Offset: pageParam * AMOUNT_ITEMS_TO_LOAD,
          Limit: AMOUNT_ITEMS_TO_LOAD,
        })
        .then((items) => commonAPIDataSelector(items)),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
  });

  const assets = useMemo(() => assetsData?.pages.flatMap((x) => x.items ?? []) ?? [], [assetsData]);

  if (assetsError) {
    return <ErrorPage error={assetsError} />;
  }

  if (isPendingAssets) {
    return <FullSizeLoader withPadding />;
  }

  return children({
    assets,
    hasMoreAssets,
    isFetchingMoreAssets,
    fetchMoreAssets,
  });
}
