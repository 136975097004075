import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { ChatDto } from "api/types";
import users01Icon from "assets/icons/users-01.svg";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FormattedDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { Capture2, Overline2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { UserDeletedTag } from "components/UserDeletedTag/UserDeletedTag";
import { cutOffAfterFirstLine, limitTextByChar } from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { Paperclip } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { SystemChatMessage } from "./SystemChatMessage";

const CHAR_LIMIT = 120;

interface ChatListItemProps {
  chatDetails: ChatDto;
  index: number;
  isLast: boolean;
}

export function ChatListItem({ chatDetails, index, isLast }: ChatListItemProps): React.ReactNode {
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const projectId = useProjectId();
  const showFlashToast = useFlashToast();

  const mutationDeleteChat = useMutation({
    mutationFn: () => api.deleteChatsByIdV2(chatDetails.id),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("page.chats.delete-chat.success") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_LIST(projectId) });
      queryClient.removeQueries({
        queryKey: QUERY_KEYS.CHATS_REPLIES(projectId, chatDetails.id || ""),
      });
    },
  });
  const mutationLeaveGroupChat = useMutation({
    mutationFn: () => api.postChatsMembersLeaveV2(chatDetails.id),
    onSuccess: () => {
      showFlashToast({ type: "success", title: t("component.group-chat-leave-modal.success") });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_LIST(projectId) });
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.CHATS_MEMBERS(projectId, chatDetails.id) });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("component.group-chat-leave-modal.error"),
      });
    },
  });

  const isChatGroupChat = chatDetails.chatType === "group";
  const isLeaveActionAvailable =
    isChatGroupChat && chatDetails.groupChatInfo?.isMember && chatDetails.groupChatInfo?.canLeave;
  const isDeleteActionAvailable = !isChatGroupChat || !chatDetails.groupChatInfo?.isMember;

  const menuActions: ContextMenuAction[] = [];
  if (isLeaveActionAvailable) {
    menuActions.push({
      text: t("common.action.leave"),
      callback: mutationLeaveGroupChat.mutate,
    });
  }
  if (isDeleteActionAvailable) {
    menuActions.push({
      text: t("common.action.delete"),
      callback: mutationDeleteChat.mutate,
    });
  }

  return (
    <li data-testid="conversation-row">
      <Link
        className={twJoin(
          "relative flex cursor-pointer items-center justify-between border-b border-b-grey-lightest p-4 hover:drop-shadow-sm focus:outline-none focus-visible:cursor-pointer focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-aop-basic-blue active:bg-grey-lightest",
          index % 2 === 0 ? "bg-white" : "bg-aop-light-grey",
          index === 0 && "rounded-t-md",
          isLast && "rounded-b-md",
        )}
        to={
          chatDetails.chatType === "single"
            ? routes.users.details({ slug, id: chatDetails.privateChatInfo!.receiver.id })
            : routes.chats.details({ slug, id: chatDetails.id })
        }
      >
        <div className="flex w-full items-center justify-between gap-1">
          <div className="flex items-center gap-2">
            <div className="size-10 shrink-0">
              {chatDetails.chatType === "single" || chatDetails.groupChatInfo?.image ? (
                <UserAvatar
                  img={
                    chatDetails.chatType === "single"
                      ? chatDetails.privateChatInfo!.receiver.avatar
                      : chatDetails.groupChatInfo!.image
                  }
                  isUserDeleted={
                    chatDetails.chatType === "single" ? !!chatDetails.privateChatInfo!.receiver.deletedAt : false
                  }
                  hideDeletedIcon
                />
              ) : (
                <div className="flex size-10 items-center justify-center rounded-full border border-grey-lightest p-1 text-grey-light">
                  <Icon name={users01Icon} size={24} />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-0">
              <div className="flex items-center gap-1">
                <span
                  className={twJoin(
                    "font-semibold",
                    chatDetails.chatType === "single" && chatDetails.privateChatInfo!.receiver.deletedAt
                      ? "text-grey"
                      : "text-black",
                  )}
                >
                  {chatDetails.chatType === "single"
                    ? chatDetails.privateChatInfo!.receiver.fullName
                    : chatDetails.groupChatInfo!.name}
                </span>
                {chatDetails.chatType === "single" && !!chatDetails.privateChatInfo!.receiver.deletedAt && (
                  <UserDeletedTag />
                )}
              </div>
              <div>
                {chatDetails.latestReply?.replyType?.includes("system") ? (
                  <SystemChatMessage
                    chatName={chatDetails.groupChatInfo?.name}
                    chatMessage={chatDetails.latestReply}
                    className="text-grey-darker"
                  />
                ) : chatDetails.latestReply?.text ? (
                  <Capture2 className="sentry-block text-grey-darker">
                    {chatDetails.chatType === "group" ? (
                      <span className="font-semibold">{`${chatDetails.latestReply.author?.id === sessionUser.id ? t("page.chats.list.self-message") : chatDetails.latestReply.author?.fullName}: `}</span>
                    ) : null}
                    {cutOffAfterFirstLine(limitTextByChar(chatDetails.latestReply.text, CHAR_LIMIT))}
                  </Capture2>
                ) : (
                  chatDetails.latestReply?.image && (
                    <span className="flex items-center gap-1 text-capture2 text-grey-darker">
                      {chatDetails.chatType === "group" && (
                        <span className="font-semibold">{`${chatDetails.latestReply.author?.id === sessionUser.id ? t("page.chats.list.self-message") : chatDetails.latestReply.author?.fullName}: `}</span>
                      )}
                      <Paperclip className="text-grey-light" size={16} />
                      <Capture2>{t("page.chats.attachment")}</Capture2>
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex flex-col items-end justify-start gap-2">
              {chatDetails.latestReply?.sentAt && (
                <p
                  className={twJoin("text-sm", chatDetails.hasUnreadReply ? "text-aop-basic-blue" : "text-grey-darker")}
                >
                  <FormattedDate format="datetime" date={chatDetails.latestReply?.sentAt} />
                </p>
              )}
              {chatDetails.unreadReplies > 0 && (
                <div className="flex size-5 items-center justify-center rounded-full bg-aop-basic-blue p-1 text-white">
                  <Overline2>{chatDetails.unreadReplies}</Overline2>
                </div>
              )}
            </div>
            {menuActions.length > 0 && <ContextMenu actions={menuActions} />}
          </div>
        </div>
      </Link>
    </li>
  );
}
