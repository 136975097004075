import { Popover } from "@ark-ui/react";
import { IconButton } from "components/Button/IconButton";
import type { UseBoolHandlers } from "hooks/useBool";
import { useBool } from "hooks/useBool";
import { useKey } from "hooks/useKey";
import { memo } from "react";
import { MoreVertical as MoreVerticalIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export interface ContextMenuAction {
  text: string;
  dataTestId?: string;
  icon?: React.ReactNode;
  callback: () => void;
  status?: {
    disabled: boolean;
    disabledText?: string;
  };
}

export interface ContextMenuProps {
  actions: ContextMenuAction[];
  children?: (props: { isOpen: boolean; openHandlers: UseBoolHandlers }) => React.ReactNode;
}

export const ContextMenu = memo(function ContextMenu({ actions, ...props }: ContextMenuProps): React.ReactNode {
  const { t } = useTranslation();
  const [isOpen, openHandlers] = useBool();

  useKey("Escape", openHandlers.setFalse, actions.length > 0);

  if (actions.length === 0) {
    return null;
  }

  return (
    <Popover.Root
      open={isOpen}
      onInteractOutside={openHandlers.setFalse}
      positioning={{
        placement: "right-start",
        offset: {
          // For the icon button we want to have the context menu closer to the icon button as it's transparent.
          // All other buttons should have the context menu a bit away from the button.
          mainAxis: props.children ? 5 : -5,
          crossAxis: -25,
        },
      }}
      unmountOnExit
      lazyMount
    >
      <div data-testid="context-menu">
        {props.children == null ? (
          <IconButton
            // When context menu is open we dont want to show the tooltip anymore
            // Otherwise the tooltip shows up after you click to open the context menu
            title={isOpen ? "" : t("component.context-menu.action.open")}
            isPressed={isOpen}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (isOpen) {
                openHandlers.setFalse();
              } else {
                openHandlers.setTrue();
              }
            }}
            className="border-none bg-transparent hover:bg-transparent"
          >
            <MoreVerticalIcon size={24} />
          </IconButton>
        ) : (
          props.children({ isOpen, openHandlers })
        )}
        <Popover.Positioner className="!z-50">
          <Popover.Content asChild>
            <div
              className={twJoin(
                "min-w-28 rounded border border-grey-lighter bg-white text-black shadow-md",
                "repeat-1 data-[placement=left-end]:origin-bottom-right data-[placement=left-start]:origin-top-right data-[placement=right-end]:origin-bottom-left data-[placement=right-start]:origin-top-left",
                "data-[state=open]:animate-in data-[state=open]:fade-in data-[state=open]:zoom-in",
                "data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=closed]:zoom-out",
              )}
            >
              <div data-testid="context-menu-action-list" className="flex flex-col">
                {actions.map((action) => (
                  <button
                    key={action.text}
                    className="flex h-8 w-full items-center gap-1 px-2 text-left hover:bg-blue-lightest focus-visible:bg-blue-lightest focus-visible:outline-none disabled:cursor-default disabled:bg-white disabled:text-grey-light disabled:[&_svg]:stroke-grey-light"
                    type="button"
                    data-testid={action.dataTestId}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      action.callback();
                      openHandlers.setFalse();
                    }}
                    disabled={action.status?.disabled}
                  >
                    {action.icon}
                    {action.text}
                  </button>
                ))}
              </div>
            </div>
          </Popover.Content>
        </Popover.Positioner>
        <Popover.Anchor />
      </div>
    </Popover.Root>
  );
});
