import type { Placement } from "@floating-ui/react";
import { autoUpdate, FloatingPortal, useFloating } from "@floating-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type { LanguageDto } from "api/types";
import chevronDownIcon from "assets/icons/chevron-down.svg";
import logOutIcon from "assets/icons/log-out-01.svg";
import userEditIcon from "assets/icons/user-edit.svg";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { ToggleButtonGroup } from "components/ToggleButtonGroup/ToggleButtonGroup";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { getFlagIcon } from "helpers/flags";
import { useLanguages } from "helpers/languages";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUserOptional } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useClickOutside } from "hooks/useClickOutside";
import { useSidebarManager } from "hooks/useSidebar";
import { useSlug } from "hooks/useSlug";
import { usePostHog } from "posthog-js/react";
import { QUERY_KEYS } from "query-keys";
import { type ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

interface NavigationSidebarUserMenuProps {
  isPortfolio?: boolean;
}

export function NavigationSidebarUserMenu({ isPortfolio }: NavigationSidebarUserMenuProps): React.ReactNode {
  const { t } = useTranslation();
  const [sessionUser] = useSessionUserOptional();
  const { isCollapsed } = useSidebarManager();
  const userMenuRef = useRef<HTMLDivElement | null>(null);
  const [isMenuOpen, menuOpenHandlers] = useBool(false);
  const slug = useSlug({ optional: true });
  const postHog = usePostHog();

  const placement: Placement = isCollapsed ? "right-end" : "top";
  const { refs, floatingStyles } = useFloating({ whileElementsMounted: autoUpdate, placement });

  useClickOutside(userMenuRef, menuOpenHandlers.setFalse, isMenuOpen);

  const languages = useLanguages();
  const changeLanguage = useChangeLanguage();

  const languageOptions =
    languages.data?.map((l) => ({
      label: (
        <span className="flex flex-row items-center gap-1">
          {getFlagIcon({ languageId: l.id, width: 16 })}
          <span className="block xl:block xs:hidden">{l.description}</span>
          <span className="hidden uppercase xl:hidden xs:block" aria-hidden>
            {l.id}
          </span>
        </span>
      ),
      value: l.id,
    })) || [];

  const selectedLanguage = languageOptions.find((l) => l.value === sessionUser?.language?.id) || languageOptions[0];

  return (
    <>
      <div className={twJoin("relative flex justify-center", isCollapsed ? "w-fit" : "w-full")} ref={userMenuRef}>
        <div className="w-full rounded-lg ring-1 ring-inset ring-grey-lightest">
          <button
            className={twJoin(
              "relative z-0 flex w-full flex-row items-center justify-between gap-2 rounded-lg outline-none transition-colors focus-visible:bg-aop-basic-blue-lightest hocus:bg-aop-basic-blue-lightest",
              isCollapsed ? "p-1" : "p-2",
              isMenuOpen &&
                isCollapsed &&
                "after:absolute after:left-12 after:line-clamp-1 after:h-full after:w-[260px] after:text-left after:leading-10 after:content-[attr(data-name)]",
            )}
            data-name={sessionUser?.fullName || "..."}
            onClick={menuOpenHandlers.toggle}
            ref={refs.setReference}
          >
            <div className="flex flex-row items-center gap-2">
              <div className="size-8">
                <UserAvatar img={sessionUser?.avatar} />
              </div>
              {!isCollapsed && (
                <span className="line-clamp-1 text-left">
                  {sessionUser?.fullName || <LoadingIcon className="w-5 delay-300 duration-300 animate-in fade-in" />}
                </span>
              )}
            </div>
            {!isCollapsed && (
              <Icon
                name={chevronDownIcon}
                size={16}
                className={twJoin("transition-transform", isMenuOpen ? "" : "rotate-180")}
              />
            )}
          </button>
        </div>
      </div>

      {isMenuOpen && (
        <FloatingPortal>
          <div
            className={twJoin(
              "z-40 flex h-fit flex-col gap-2 rounded-lg border border-grey-lightest bg-white",
              placement === "top" && "!-top-2 w-[256px]",
              placement === "right-end" && "!left-2 w-[320px]",
            )}
            ref={refs.setFloating}
            style={floatingStyles}
          >
            {!isPortfolio && (
              <div className="flex flex-col gap-1">
                <span className="pl-4 pt-4 text-xs font-bold uppercase text-grey-darkest">
                  {t("component.user-menu.language")}
                </span>
                <div className="p-2">
                  <ToggleButtonGroup<LanguageDto["id"], ReactNode>
                    fillWidth
                    selected={selectedLanguage}
                    onChange={(option) => changeLanguage(option.value)}
                    options={languageOptions}
                  />
                </div>
              </div>
            )}
            {!isPortfolio && slug && sessionUser && sessionUser.role.type !== "resident" && (
              <Link
                className="flex w-full flex-row items-center gap-2 p-2 outline-none focus-visible:bg-aop-basic-blue-lightest hocus:bg-aop-basic-blue-lightest"
                to={routes.users.edit({ slug, id: sessionUser.id })}
                onClick={() => {
                  menuOpenHandlers.setFalse();
                  postHog.reset(true);
                }}
              >
                <span className="p-2">
                  <Icon name={userEditIcon} size={16} />
                </span>
                <span>{t("component.user-menu.user-settings")}</span>
              </Link>
            )}
            <Link
              className="flex w-full flex-row items-center gap-2 p-2 outline-none focus-visible:bg-aop-basic-blue-lightest hocus:bg-aop-basic-blue-lightest"
              to={routes.logout()}
              onClick={() => {
                menuOpenHandlers.setFalse();
                postHog.reset(true);
              }}
            >
              <span className="p-2">
                <Icon name={logOutIcon} size={16} />
              </span>
              <span>{t("component.user-menu.log-out")}</span>
            </Link>
          </div>
        </FloatingPortal>
      )}
    </>
  );
}

function useChangeLanguage() {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const api = useApi();
  const showFlashToast = useFlashToast();

  const { mutateAsync: changeLanguage } = useMutation({
    mutationFn: (languageId: LanguageDto["id"]) => api.putSelfLanguageV1({ languageId }).then(({ data }) => data),
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.self.action.change-language.notification.error"),
      });
    },
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.SELF(projectId),
      });
    },
  });

  return changeLanguage;
}
