import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import barchartIcon from "assets/icons/bar-chart-01.svg";
import bellIcon from "assets/icons/bell-01.svg";
import calendarIcon from "assets/icons/calendar.svg";
import bookingIcon from "assets/icons/calendar-heart-02.svg";
import dotsGridIcon from "assets/icons/dots-grid.svg";
import file02Icon from "assets/icons/file-02.svg";
import homeIcon from "assets/icons/home-02.svg";
import inbox01Icon from "assets/icons/inbox-01.svg";
import messageChatCircleIcon from "assets/icons/message-chat-circle.svg";
import piechartIcon from "assets/icons/pie-chart-01.svg";
import searchRefractionIcon from "assets/icons/search-refraction.svg";
import settingsIcon from "assets/icons/settings-01.svg";
import stars02Icon from "assets/icons/stars-02.svg";
import toolIcon from "assets/icons/tool-02.svg";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { NavigationSidebarUserMenu } from "components/NavigationSidebar/NavigationSidebarUserMenu";
import { Sidebar } from "components/Sidebar/Sidebar";
import { SidebarItem } from "components/Sidebar/SidebarItem";
import type { SidebarBaseItemType, SidebarBodyRootProps, SidebarItemType } from "components/Sidebar/types";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUserOptional } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useConfig } from "hooks/useConfig";
import { useKey } from "hooks/useKey";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useSignalRHub, useSignalRSubscription } from "hooks/useSignalR";
import { useSlug } from "hooks/useSlug";
import { canList as canListDocuments } from "modules/documents/permissions";
import { canList as canListEvents } from "modules/events/permissions";
import { canListMessages } from "modules/messages/permissions";
import { canListAnySurvey } from "modules/surveys/permissions";
import { canListUsers } from "modules/users/permissions";
import { usePostHog } from "posthog-js/react";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { ProjectSidebarHeader } from "./ProjectSidebarHeader";

interface ProjectSidebarProps extends Omit<SidebarBodyRootProps, "children"> {}

export function ProjectSidebar({
  isOpened,
  isCollapsed,
  onToggleOpen,
  onToggleCollapse,
}: ProjectSidebarProps): React.ReactNode {
  const [amountUnreadMessages, setAmountUnreadMessages] = useState<{ [projectId: string]: number }>({});
  const [isProjectSwitcherVisible, projectSwitcherVisibilityHandlers] = useBool();

  const projectId = useProjectId();
  const { t } = useTranslation();
  const slug = useSlug();
  const connectedProjects = useConnectedProjects();
  const api = useApi();
  const [sessionUser] = useSessionUserOptional({ keepPreviousData: true });
  const isDesktop = useScreenIsBiggerThan("md");
  const postHog = usePostHog();

  const { setting: allowBookings, loading: isLoadingBookingsConfig } = useConfig("allowBookings");

  const { signalRConnection } = useSignalRHub("notification-hub", {
    query: `userId=${sessionUser?.id}`,
    disabled: !sessionUser,
  });
  const { data: adminNotificationStats } = useQuery({
    queryKey: QUERY_KEYS.ADMIN_NOTIFICATION_STATUS(projectId),
    queryFn: () => api.getAdminNotificationsStatusV1(),
    select: commonAPIDataSelector,
    staleTime: 30 * 1000,
    refetchInterval: 30 * 1000,
    enabled: sessionUser?.isAdmin,
  });

  const onNewChatMessage = useCallback(
    (...args: [{ unreadChats: number; fromConnectionStarted: boolean }]) => {
      if (args[0].unreadChats > 0) {
        setAmountUnreadMessages((x) => ({ ...x, [projectId]: args[0].unreadChats }));
      } else {
        setAmountUnreadMessages((x) => ({ ...x, [projectId]: 0 }));
      }
    },
    [projectId],
  );

  useSignalRSubscription(signalRConnection, "UpdateUnreadChatsCount", onNewChatMessage);
  useKey("Escape", projectSwitcherVisibilityHandlers.setFalse, isProjectSwitcherVisible);

  const project = sessionUser?.project;
  const canViewPortfolio = useMemo(
    () => connectedProjects.data?.some((x) => x.userRole !== "resident"),
    [connectedProjects.data],
  );

  const items: SidebarItemType[] = [
    ...(!isDesktop && canViewPortfolio
      ? [
          {
            label: t("navigation.portfolio.portfolio"),
            to: routes.portfolio.overview(),
            icon: <Icon name={dotsGridIcon} />,
            exact: true,
          },
        ]
      : []),
    {
      label: t("navigation.home"),
      to: routes.home.home({ slug }),
      icon: <Icon name={homeIcon} />,
      permission: (x) => x.isAdmin && !x.isMaintenance,
      exact: true,
    },
    {
      label: t("navigation.analytics"),
      to: routes.analytics.overview({ slug }),
      icon: <Icon name={piechartIcon} />,
      permission: (x) => x.isAdmin && !x.isMaintenance && !x.isCaretaker,
    },
    {
      label: t("navigation.discover"),
      icon: <Icon name={searchRefractionIcon} />,
      "data-testid": "navigation-discovery-group",
      type: "group",
      items: [
        {
          label: t("navigation.discover.neighbours"),
          to: routes.users.list({ slug }),
          permission: (x) => x.isAdmin && x.userManagement.canListUsers,
          onClick: () => postHog.capture("clicked_all_neighbours"),
        },
        {
          label: t("navigation.discover.addresses"),
          to: routes.addresses.list({ slug }),
          permission: (x) => x.isAdmin && x.addressManagement.canListAddresses,
        },
        ...(project?.type === "companyBased"
          ? ([
              {
                label: t("navigation.discover.companies"),
                to: routes.companies.list({ slug }),
                permission: (x) => x.addressManagement.canManageCompanies,
              },
            ] as SidebarBaseItemType[])
          : []),
        {
          label: t("navigation.discover.groups"),
          to: routes.interestGroups.list({ slug }),
          "data-testid": "navigation-link-interest-groups",
        },
        {
          label: t("navigation.discover.help"),
          to: routes.helpCategories.list({ slug }),
          "data-testid": "navigation-link-help-categories",
        },
      ],
    },
    {
      label: t("navigation.community-feed"),
      to: routes.messageFeed.list({ slug }),
      icon: <Icon name={file02Icon} />,
      permission: canListMessages,
      onClick: () => postHog.capture("clicked_community_feed"),
    },
    {
      label: t("navigation.chats"),
      to: routes.chats.list({ slug }),
      icon: <Icon name={messageChatCircleIcon} />,
      isNewEventAvailable: amountUnreadMessages[projectId] > 0,
      newEventCount: amountUnreadMessages[projectId],
      permission: canListUsers,
    },
    {
      label: t("navigation.calendar"),
      to: routes.calendar.list({ slug }),
      icon: <Icon name={calendarIcon} />,
      "data-testid": "navigation-calendar",
      permission: canListEvents,
      onClick: () => postHog.capture("clicked_calendar"),
    },
    {
      label: t("navigation.services"),
      to: routes.servicePartners.list({ slug }),
      icon: <Icon name={stars02Icon} />,
      "data-testid": "navigation-service-list",
    },
    ...(allowBookings
      ? ([
          {
            label: t("navigation.bookings"),
            icon: <Icon name={bookingIcon} />,
            type: "group",
            items: [
              {
                label: t("navigation.bookings.assets"),
                to: routes.bookings.list({ slug }),
                permission: (x) => x.assets.canViewSchedule,
              },
              {
                label: t("navigation.bookings.reservations"),
                to: routes.reservations.list({ slug }),
                permission: (x) => x.assets.canViewSchedule,
              },
            ],
          },
        ] as SidebarItemType[])
      : []),
    ...(sessionUser?.role.type === "resident"
      ? ([
          {
            label: t("navigation.resident-tickets"),
            to: routes.tickets.list({ slug }),
            icon: <Icon name={toolIcon} />,
            permission: (x) => x.canCreateTicket,
            "data-testid": "navigation-resident-tickets",
          },
        ] as SidebarItemType[])
      : [
          {
            label: t("navigation.tickets"),
            to: routes.tickets.list({ slug }),
            icon: <Icon name={toolIcon} />,
            "data-testid": "navigation-tickets",
            onClick: () => postHog.capture("clicked_all_tickets"),
          },
        ]),
    {
      label: t("navigation.documents"),
      to: routes.documents.rootList({ slug }),
      icon: <Icon name={inbox01Icon} />,
      "data-testid": "navigation-documents-root-list",
      permission: canListDocuments,
    },
    {
      label: t("navigation.surveys-group"),
      icon: <Icon name={barchartIcon} />,
      type: "group",
      items: [
        {
          label: t("navigation.surveys"),
          to: routes.surveys.list({ slug }),
          permission: canListAnySurvey,
        },
        {
          label: t("navigation.automated-survey-queues"),
          to: routes.automatedSurveyQueues.list({ slug }),
          permission: (x) => x.isSuperAdmin,
        },
      ],
    },
    {
      label: t("navigation.admin"),
      icon: <Icon name={settingsIcon} />,
      "data-testid": "navigation-management-group",
      type: "group",
      items: [
        {
          label: t("navigation.admin.buildings"),
          to: routes.buildings.list({ slug }),
          permission: (x) => x.addressManagement.canManageBuildings,
        },
        {
          label: t("navigation.admin.addresses"),
          to: routes.admin.addressBulkUpload({ slug }),
          permission: (x) => x.isSuperAdmin,
        },
        {
          label: t("navigation.admin.companies"),
          to: routes.admin.companyBulkUpload({ slug }),
          permission: (x) => x.isSuperAdmin && project?.type === "companyBased",
        },
        {
          label: t("navigation.platform.user-upload"),
          to: routes.admin.userBulkUpload({ slug }),
          permission: (x) => x.isSuperAdmin,
        },
        {
          label: t("navigation.admin.settings"),
          to: routes.projects.edit({ slug }),
          permission: (x) => x.isSuperAdmin,
        },
        {
          label: t("navigation.admin.styling"),
          to: routes.projects.styling({ slug }),
          permission: (x) => x.isSuperAdmin,
        },
        {
          label: t("navigation.admin.onboarding"),
          to: routes.onboardingScreens.list({ slug }),
          permission: (x) => x.isSuperAdmin,
        },
        {
          label: t("navigation.admin.roles"),
          to: routes.roles.list({ slug }),
          "data-testid": "navigation-role-list",
          permission: (x) => x.userManagement.canManageRoles,
        },
        {
          label: t("navigation.admin.plus-button"),
          to: routes.projects.plusButtonOptions({ slug }),
          permission: (x) => x.isSuperAdmin,
        },
        {
          label: t("navigation.admin.integrations"),
          to: routes.projects.integrations({ slug }),
          permission: (x) => x.isSuperAdmin,
        },
      ],
    },
  ];

  return (
    <Sidebar.Root {...{ isOpened, isCollapsed, onToggleCollapse, onToggleOpen }}>
      {/* Loader */}
      <AnimatePresence>
        {(!sessionUser || isLoadingBookingsConfig) && (
          <motion.div className="absolute inset-0 z-20 size-full bg-white">
            <FullSizeLoader size="small" />
          </motion.div>
        )}
      </AnimatePresence>

      <ProjectSidebarHeader {...{ onToggleOpen }} />

      <Sidebar.Body {...{ items }}>
        {sessionUser?.isAdmin && isDesktop && (
          <div className="mt-8 flex w-full flex-col gap-2 py-4">
            {!isCollapsed && (
              <h3 className="text-xs font-bold uppercase tracking-wide text-grey-darker">
                {t("navigation.notifications.section-title")}
              </h3>
            )}
            <SidebarItem
              label={t("navigation.notifications.title")}
              newEventCount={adminNotificationStats?.amount}
              isNewEventAvailable={adminNotificationStats?.hasUnread}
              icon={<Icon name={bellIcon} size={16} />}
              to={routes.adminNotifications.list({ slug })}
            ></SidebarItem>
          </div>
        )}
      </Sidebar.Body>
      <Sidebar.Footer>
        <NavigationSidebarUserMenu />
      </Sidebar.Footer>
    </Sidebar.Root>
  );
}
