import type { GroupDto, UserDto } from "api/types";
import { Anchor } from "components/Anchor/Anchor";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import type { Label } from "components/Label/Label";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { UserDeletedTag } from "components/UserDeletedTag/UserDeletedTag";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { useSlug } from "hooks/useSlug";
import type { IconProps } from "react-feather";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

interface Props {
  user: UserDto;
  group?: GroupDto;
  dateSubtitle?: React.ReactNode;
  warningHeaders?: { icon: React.ReactElement<IconProps>; text: string }[];
  actions?: ContextMenuAction[];
  labels?: React.ReactElement<React.ComponentPropsWithoutRef<typeof Label>>[];
  ring?: "greyed-out" | "unread" | "reported";
  children: React.ReactNode;
  onClick?: () => void;
}

export function CommunityItem({
  ring,
  dateSubtitle,
  onClick,
  warningHeaders,
  group,
  user,
  children,
  actions,
  labels,
}: Props): React.ReactNode {
  const slug = useSlug();
  const { t } = useTranslation();

  return (
    <div
      className={twJoin(
        "flex flex-1 flex-col gap-1 rounded-lg bg-white shadow-md",
        ring === "greyed-out"
          ? "ring-1 ring-grey-lightest"
          : ring === "unread"
            ? "ring-1 ring-blue-dark"
            : ring === "reported"
              ? "ring-1 ring-red"
              : undefined,
      )}
      onClick={onClick}
    >
      {warningHeaders && warningHeaders.length > 0 ? (
        <div className="flex flex-col rounded-t-lg bg-blue-lightest text-blue-darker">
          {warningHeaders?.map((x) => (
            <div className="flex items-center gap-2 px-4 py-2" key={x.text}>
              <span className="shrink-0 opacity-80">{x.icon}</span>
              <div className="inline-flex flex-col items-center gap-1">
                <span className="align-middle">{x.text}</span>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <div className="flex flex-col gap-4 p-4">
        <div className="flex gap-4">
          <div className="flex w-full flex-wrap gap-4">
            <div className="flex grow items-center">
              <div className="size-10 shrink-0">
                <UserAvatarLink user={user} hideDeletedIcon />
              </div>
              <div className="ml-3 text-sm">
                <span className="line-clamp-1">
                  <UserNameLink user={user}>
                    <span className={twJoin("font-semibold", user.deletedAt ? "text-grey" : "text-black")}>
                      {user.fullName}
                    </span>
                  </UserNameLink>
                  {user.deletedAt && (
                    <span>
                      {" "}
                      <UserDeletedTag />
                    </span>
                  )}
                  {group && (
                    <>
                      {" "}
                      <Anchor
                        to={
                          group.type === "helpCategory"
                            ? routes.helpCategories.details({ slug, id: group.id })
                            : routes.interestGroups.details({ slug, id: group.id })
                        }
                        isItalic
                        style="inherit"
                      >
                        {t("component.community-post.posted-in-group", { group: group.name })}
                      </Anchor>
                    </>
                  )}
                </span>
                {dateSubtitle ? <span className="line-clamp-1 text-grey">{dateSubtitle}</span> : null}
              </div>
            </div>

            {labels && labels.length > 0 ? (
              <div className="order-first flex w-full flex-wrap items-center gap-2 sm:order-none sm:w-auto">
                {labels.map((label, idx) => (
                  <div key={`label_${idx}`}>{label}</div>
                ))}
              </div>
            ) : null}
          </div>

          {actions && actions.length > 0 && (
            <div className="-mr-2 mt-1 self-end pb-1">
              <ContextMenu actions={actions} />
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
}
