import type { EventDetailsDto } from "api/types";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { useTranslation } from "react-i18next";

import { EventDetails } from "./components/EventDetails";
import { EventDetailsParticipantsSection } from "./components/EventDetailsParticipantsSection";

export interface LayoutProps {
  eventDetails: EventDetailsDto;
}
export function Layout(props: LayoutProps): React.ReactNode {
  const { t } = useTranslation();

  return (
    <DocumentPaper theme="minimal" title={t("page.event.details.title")}>
      <div className="flex flex-wrap gap-5 [&>div]:max-w-[840px]">
        <div className="w-full min-w-96">
          <EventDetails event={props.eventDetails} />
        </div>
        <div className="min-w-96 flex-1">
          <EventDetailsParticipantsSection event={props.eventDetails} />
        </div>
      </div>
    </DocumentPaper>
  );
}
