import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  ConstraintListItemDto,
  LanguageDto,
  TicketCategoryDto,
  UserDto,
  UserRoleRepairCategoryPermissionsDto,
} from "api/types";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "components/Accordion/Accordion";
import { AudienceSelector } from "components/AudienceSelector/AudienceSelector";
import { Form } from "components/Form/Form";
import { FormCheckbox } from "components/Form/FormCheckbox";
import { FormContent } from "components/Form/FormContent";
import { FormErrorWrapper } from "components/Form/FormErrorWrapper";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormRadio } from "components/Form/FormRadio";
import { FormSelect } from "components/Form/FormSelect";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { PermissionListLayout, PermissionWrapper } from "components/Permission/Permission";
import { Wizard, WizardStep } from "components/Wizard/Wizard";
import type { FormTranslations } from "helpers/languages";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import type { FormPermissions } from "helpers/permissions";
import { createRequiredStringRule } from "helpers/rules";
import { useProjectId } from "hooks/Network/useProjectId";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface LayoutProps {
  languages: LanguageDto[];
  isEditMode: boolean;
  defaultFormValues: TicketCategoryDetailsFields;
  isSubmitting: boolean;
  onSubmit: (data: TicketCategoryDetailsFields) => void;
}

const TABS_ID = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3,
};
export const DAYS_VARIANTS = ["14", "30", "60", "90"] as const;
const NAME_MAX_LENGTH = 30;

export interface TicketCategoryDetailsFields {
  translations: FormTranslations;
  notificationType: TicketCategoryDto["notificationType"];
  closeAfterInactiveDayAmount: `${number}`;
  disabled: "true" | "false";
  defaultAssignee?: UserDto;
  audience: ConstraintListItemDto[];
  permissions: FormPermissions<UserRoleRepairCategoryPermissionsDto>;
}

export function Layout(props: LayoutProps): React.ReactNode {
  const projectId = useProjectId();
  const { languages, isEditMode, defaultFormValues } = props;
  const { t } = useTranslation();
  const formMethods = useForm<TicketCategoryDetailsFields>({
    mode: "onChange",
    defaultValues: defaultFormValues,
  });

  const closeAfterInactiveDayAmount = useWatch<TicketCategoryDetailsFields, "closeAfterInactiveDayAmount">({
    control: formMethods.control,
    name: "closeAfterInactiveDayAmount",
  });
  const audience = useWatch<TicketCategoryDetailsFields, "audience">({
    control: formMethods.control,
    name: "audience",
  });

  const commonWizardActionValidation = async () => {
    return await formMethods.trigger();
  };

  const canBeAssignedPermissions = useWatch<TicketCategoryDetailsFields, `permissions.${string}.canBeAssigned`[]>({
    control: formMethods.control,
    name: Object.keys(defaultFormValues.permissions).map<`permissions.${string}.canBeAssigned`>(
      (x) => `permissions.${x}.canBeAssigned`,
    ),
  }) as boolean[];

  const canBeAssignedRoleIds = Object.entries(defaultFormValues.permissions)
    .filter((_, index) => canBeAssignedPermissions[index])
    .map(([roleId]) => roleId);

  const api = useApi();
  const { data: possibleAssignees = [], isFetching: isPossibleAssigneesFetching } = useQuery({
    queryKey: QUERY_KEYS.ASSIGNEES_BY_ROLES(projectId, canBeAssignedRoleIds),
    queryFn: () => api.getUsersRepairAssigneesByRolesV1({ roles: canBeAssignedRoleIds }),
    select: commonAPIDataSelector,
  });

  const [prevDefaultAssigneeBeforeReset, setPrevDefaultAssigneeBeforeReset] = useState<UserDto | undefined>(undefined);

  const defaultAssignee = useWatch<TicketCategoryDetailsFields, "defaultAssignee">({
    control: formMethods.control,
    name: "defaultAssignee",
  });

  useEffect(() => {
    if (defaultAssignee) {
      // Default assignee was changed by user, so prev selection should be erased
      setPrevDefaultAssigneeBeforeReset(undefined);
    }
  }, [defaultAssignee, setPrevDefaultAssigneeBeforeReset]);

  const { setValue } = formMethods;
  useEffect(() => {
    if (isPossibleAssigneesFetching) {
      return;
    }

    if (prevDefaultAssigneeBeforeReset && possibleAssignees.some((x) => x.id === prevDefaultAssigneeBeforeReset.id)) {
      // Restore prev selection as permissions were changed
      setValue("defaultAssignee", prevDefaultAssigneeBeforeReset);
    }
  }, [defaultAssignee, possibleAssignees, setValue, t, isPossibleAssigneesFetching, prevDefaultAssigneeBeforeReset]);

  return (
    <DocumentPaper
      theme="no-gaps"
      title={
        isEditMode
          ? t("page.repair-category.create-or-edit.edit-mode.title")
          : t("page.repair-category.create-or-edit.create-mode.title")
      }
    >
      <Form formMethods={formMethods} onSubmit={props.onSubmit} data-testid="repair-category-form">
        <Wizard
          id="repair-category-wizard"
          actionsText={{
            finish: isEditMode ? t("component.wizard.action.edit.finish") : t("component.wizard.action.create.finish"),
          }}
          isSubmitting={props.isSubmitting}
          onFinish={formMethods.handleSubmit(props.onSubmit)}
          strictOrder={!isEditMode}
        >
          <WizardStep
            id={TABS_ID.STEP1}
            hasFinish={isEditMode}
            canLeave={commonWizardActionValidation}
            title={
              <>
                <span>{t("component.wizard.step", { step: 1 })}</span>
                <span>{t("component.wizard.step-general")}</span>
              </>
            }
          >
            <FormContent data-testid="repair-category-step-1">
              {languages.map((lng) => (
                <FormField
                  key={lng.id}
                  htmlFor={`translations-${lng.id}`}
                  label={`${t("model.repair-category.name")} (${lng.poEditorCode})`}
                  required
                >
                  <FormInput<TicketCategoryDetailsFields, `translations.${typeof lng.id}`>
                    className="max-w-sm"
                    data-testid="repair-category-name"
                    autoComplete="off"
                    id={`translations-${lng.id}`}
                    name={`translations.${lng.id}`}
                    rules={{
                      validate: {
                        required: createRequiredStringRule(t, "model.repair-category.name"),
                      },
                      maxLength: {
                        message: t("components.form.error.max-length", {
                          length: NAME_MAX_LENGTH,
                        }),
                        value: NAME_MAX_LENGTH,
                      },
                    }}
                    placeholder={`${t("model.repair-category.name.placeholder")} ${lng.description}`}
                  />
                </FormField>
              ))}
              <FormField label={t("model.repair-category.notification-type")}>
                <FormErrorWrapper<TicketCategoryDetailsFields> name="notificationType">
                  <div className="flex flex-col items-start">
                    <FormRadio<TicketCategoryDetailsFields, "notificationType">
                      data-testid="repair-category-notification-type-collective"
                      name="notificationType"
                      value="canBeCollective"
                      displayValue={t("model.repair-category.notification-type.collective")}
                    />
                    <FormRadio<TicketCategoryDetailsFields, "notificationType">
                      data-testid="repair-category-notification-type-private"
                      name="notificationType"
                      value="alwaysPrivate"
                      displayValue={t("model.repair-category.notification-type.private")}
                    />
                  </div>
                </FormErrorWrapper>
              </FormField>
              <FormField
                label={t("common.entity.audience")}
                description={t("component.audience-section.description", {
                  audienceTarget: t("common.entity.section").toLowerCase(),
                })}
              >
                <AudienceSelector
                  defaultAudience={audience}
                  isEditMode={props.isEditMode}
                  onSaveAudience={(audience) => formMethods.setValue("audience", audience)}
                />
              </FormField>
              <FormField label={t("model.repair-category.activity")}>
                <FormErrorWrapper<TicketCategoryDetailsFields> name="closeAfterInactiveDayAmount">
                  <div className="flex flex-col items-start">
                    <FormRadio<TicketCategoryDetailsFields>
                      data-testid="repair-category-close-after-inactive-amount-manual"
                      name="closeAfterInactiveDayAmount"
                      value="0"
                      displayValue={t("model.repair-category.activity.infinite")}
                    />
                    {DAYS_VARIANTS.map((days) => (
                      <FormRadio<TicketCategoryDetailsFields>
                        data-testid={`repair-category-close-after-inactive-amount-${days}`}
                        key={days}
                        name="closeAfterInactiveDayAmount"
                        value={days}
                        displayValue={t("model.repair-category.activity.days", { days })}
                      />
                    ))}
                    {closeAfterInactiveDayAmount != "0" &&
                      !DAYS_VARIANTS.includes(closeAfterInactiveDayAmount as (typeof DAYS_VARIANTS)[number]) && (
                        <FormRadio<TicketCategoryDetailsFields>
                          data-testid={`repair-category-close-after-inactive-amount-${closeAfterInactiveDayAmount}`}
                          key={closeAfterInactiveDayAmount}
                          name="closeAfterInactiveDayAmount"
                          value={closeAfterInactiveDayAmount}
                          displayValue={t("model.repair-category.activity.days", {
                            days: closeAfterInactiveDayAmount,
                          })}
                        />
                      )}
                  </div>
                </FormErrorWrapper>
              </FormField>
              <FormField label={t("model.repair-category.status")}>
                <FormErrorWrapper<TicketCategoryDetailsFields> name="disabled">
                  <div className="flex flex-col items-start">
                    <FormRadio<TicketCategoryDetailsFields>
                      data-testid="repair-category-disabled-false"
                      name="disabled"
                      value="false"
                      displayValue={t("model.repair-category.status.active")}
                    />
                    <FormRadio<TicketCategoryDetailsFields, "disabled">
                      data-testid="repair-category-disabled-true"
                      name="disabled"
                      value="true"
                      displayValue={t("model.repair-category.status.disable")}
                    />
                  </div>
                </FormErrorWrapper>
              </FormField>
            </FormContent>
          </WizardStep>
          <WizardStep
            id={TABS_ID.STEP2}
            title={
              <>
                <span>{t("component.wizard.step", { step: 2 })}</span>
                <span>{t("component.wizard.step-permissions")}</span>
              </>
            }
            hasFinish={isEditMode}
            canLeave={commonWizardActionValidation}
          >
            <FormContent maxWidth="none">
              <Accordion allowMultipleExpanded allowZeroExpanded>
                {Object.entries(defaultFormValues.permissions).map(([roleId, permission]) => (
                  <AccordionItem key={roleId}>
                    <AccordionItemHeading>
                      <AccordionItemButton>{permission.name}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <PermissionListLayout>
                        <PermissionWrapper>
                          <FormCheckbox<TicketCategoryDetailsFields>
                            alignTop
                            memorise={defaultFormValues.permissions[roleId].canAddRequest}
                            name={`permissions.${roleId}.canAddRequest`}
                            label={t("model.permission.repair-category.can-add-request")}
                          />
                        </PermissionWrapper>
                        <PermissionWrapper>
                          <FormCheckbox<TicketCategoryDetailsFields>
                            alignTop
                            memorise={defaultFormValues.permissions[roleId].canCommentInternal}
                            name={`permissions.${roleId}.canCommentInternal`}
                            label={t("model.permission.repair-category.can-comment-internal")}
                          />
                        </PermissionWrapper>
                        <PermissionWrapper>
                          <FormCheckbox<TicketCategoryDetailsFields>
                            alignTop
                            memorise={defaultFormValues.permissions[roleId].canCommentPublic}
                            name={`permissions.${roleId}.canCommentPublic`}
                            label={t("model.permission.repair-category.can-comment-public")}
                          />
                        </PermissionWrapper>
                        <PermissionWrapper>
                          <FormCheckbox<TicketCategoryDetailsFields>
                            alignTop
                            memorise={defaultFormValues.permissions[roleId].canListAllRequests}
                            name={`permissions.${roleId}.canListAllRequests`}
                            label={t("model.permission.repair-category.can-list-all")}
                          />
                        </PermissionWrapper>
                        <PermissionWrapper>
                          <FormCheckbox<TicketCategoryDetailsFields>
                            alignTop
                            memorise={defaultFormValues.permissions[roleId].canListAssignedToMeRequests}
                            name={`permissions.${roleId}.canListAssignedToMeRequests`}
                            label={t("model.permission.repair-category.can-list-assigned-to-me")}
                          />
                        </PermissionWrapper>
                        <PermissionWrapper>
                          <FormCheckbox<TicketCategoryDetailsFields>
                            alignTop
                            memorise={defaultFormValues.permissions[roleId].canListCollectiveRequests}
                            name={`permissions.${roleId}.canListCollectiveRequests`}
                            label={t("model.permission.repair-category.can-list-collective")}
                          />
                        </PermissionWrapper>
                        <PermissionWrapper>
                          <FormCheckbox<TicketCategoryDetailsFields>
                            alignTop
                            memorise={defaultFormValues.permissions[roleId].canBeAssigned}
                            name={`permissions.${roleId}.canBeAssigned`}
                            label={t("model.permission.repair-category.can-be-assigned")}
                          />
                        </PermissionWrapper>
                        <PermissionWrapper>
                          <FormCheckbox<TicketCategoryDetailsFields>
                            alignTop
                            memorise={defaultFormValues.permissions[roleId].canUpdateRequestStatus}
                            name={`permissions.${roleId}.canUpdateRequestStatus`}
                            label={t("model.permission.repair-category.can-update-status")}
                          />
                        </PermissionWrapper>
                      </PermissionListLayout>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </FormContent>
          </WizardStep>
          <WizardStep
            id={TABS_ID.STEP3}
            title={
              <>
                <span>{t("component.wizard.step", { step: 3 })}</span>
                <span>{t("component.wizard.step-other")}</span>
              </>
            }
            hasFinish={isEditMode}
            canLeave={commonWizardActionValidation}
          >
            <FormContent>
              <FormField label={t("model.repair-category.assignee")} htmlFor="defaultAssignee">
                <FormSelect<TicketCategoryDetailsFields, UserDto>
                  className="max-w-sm"
                  data-testid="repair-category-default-assignee"
                  name="defaultAssignee"
                  id="defaultAssignee"
                  placeholder={t("model.repair-category.assignee.placeholder")}
                  items={possibleAssignees}
                  keySelector={(user) => user.id}
                  renderOption={(user) => user.fullName}
                  emptyItem={t("model.repair-category.assignee.no-assignee")}
                />
              </FormField>
            </FormContent>
          </WizardStep>
        </Wizard>
      </Form>
    </DocumentPaper>
  );
}
