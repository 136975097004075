import { ErrorPage } from "components/Error/ErrorPage";
import type { RouteObject } from "react-router-dom";

import { ChatDetailPage } from "./pages/Detail/ChatDetailPage";
import { ChatListPage } from "./pages/List";
import { chatsRoutes } from "./routes";

export const chatsRoute = [
  {
    path: chatsRoutes.list,
    element: <ChatFeatureWrapper />,
  },
  {
    path: chatsRoutes.details,
    element: <ChatDetailPage />,
  },
  { path: "*", element: <ErrorPage status={404} /> },
] satisfies RouteObject[];

function ChatFeatureWrapper() {
  return <ChatListPage />;
}
