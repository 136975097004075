import type { ServicePartnerPagePaginationResultDto } from "api/types";
import { Button } from "components/Button/Button";
import { ContextMenu, type ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { isDefined } from "helpers/util";
import { useSlug } from "hooks/useSlug";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

type ServicesListActionProps =
  | {
      entityType: "service";
      allowedCreateServiceTypes: ServicePartnerPagePaginationResultDto["allowedTypes"];
    }
  | {
      entityType: "asset";
      allowedCreateServiceTypes?: never;
    };

export function ServicesListAction({
  entityType,
  allowedCreateServiceTypes,
}: ServicesListActionProps): React.ReactNode {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const slug = useSlug();

  if (entityType === "service") {
    const actionsCreateService: ContextMenuAction[] = allowedCreateServiceTypes
      .map((type) => {
        if (type === "general") {
          return {
            text: t("model.service.action.new.general"),
            callback: () => navigate(routes.servicePartners.create({ slug })),
          };
        }
        if (type === "iqbi") {
          return {
            text: t("model.service.action.new.iqbi"),
            callback: () => navigate({ pathname: routes.servicePartners.create({ slug }), search: "integration=iqbi" }),
          };
        }
        if (type === "myPup") {
          return {
            text: t("model.service.action.new.mypup"),
            callback: () => {
              navigate({
                pathname: routes.servicePartners.create({ slug }),
                search: "integration=mypup",
              });
            },
          };
        }
      })
      .filter(isDefined);

    if (actionsCreateService.length > 1) {
      return (
        <ContextMenu actions={actionsCreateService}>
          {(props) => (
            <Button
              styling="primary"
              isPressed={props.isOpen}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.openHandlers.toggle();
              }}
              data-testid="service-create-page-btn"
            >
              <span className="flex items-center gap-1">{t("model.service.action.new")}</span>
            </Button>
          )}
        </ContextMenu>
      );
    } else {
      return (
        <Button
          type="link"
          className="w-full"
          href={routes.servicePartners.create({ slug })}
          data-testid="service-create-page-link"
        >
          {t("model.service.action.new")}
        </Button>
      );
    }
  } else {
    return (
      <Button type="link" href={routes.bookings.createAsset({ slug })} styling="primary">
        {t("page.bookings.list-assets.actions.new")}
      </Button>
    );
  }
}
