import type { ImageDto, UserDto } from "api/types";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Gallery } from "components/Gallery/Gallery";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Capture2, Overline2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { UserAvatarLink } from "components/UserAvatarLink/UserAvatarLink";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface ChatMessageProps {
  isOwnMessage: boolean;
  hasNextMessage: boolean;
  isRead: boolean;
  user: UserDto;
  canSeeUserDetails?: boolean;
  content?: string;
  image?: ImageDto;
  sentAt: string;
}

export function ChatMessage({
  isOwnMessage,
  hasNextMessage,
  isRead,
  user,
  canSeeUserDetails,
  content,
  image,
  sentAt,
}: ChatMessageProps): React.ReactNode {
  const { i18n } = useTranslation();

  return (
    <div
      className={twJoin("flex gap-2", isOwnMessage ? "self-end" : "self-start", !hasNextMessage && "pb-6")}
      data-testid="chat-message"
    >
      <div className={twJoin("size-9 self-end", isOwnMessage && "order-last", hasNextMessage && "invisible")}>
        {canSeeUserDetails && !isOwnMessage ? <UserAvatarLink user={user} /> : <UserAvatar img={user.avatar} />}
      </div>
      <div
        className={twJoin(
          "sentry-block relative rounded-lg p-3",
          isOwnMessage ? "bg-aop-chat-green" : "bg-grey-lightest",
        )}
      >
        <div className="flex max-w-[200px] flex-col gap-2 lg:max-w-md">
          {content && (
            <Capture2 className="max-w-full self-start">
              <LinkFormatter>{content}</LinkFormatter>
            </Capture2>
          )}
          {image && (
            <div className="max-w-full self-start">
              <Gallery images={[image]} />
            </div>
          )}
          <div className="flex items-center gap-2 self-end">
            <Overline2 className="self-end text-grey-dark">{formatDate(i18n, "time", sentAt)}</Overline2>
            {isOwnMessage && (
              <div className={twJoin("flex", isRead ? "text-aop-basic-blue" : "text-grey")}>
                <Check className="-mr-2.5" size={16} />
                <Check size={16} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
