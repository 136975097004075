import { useMsal } from "@azure/msal-react";
import type { SettingTypeOf } from "configcat-js";
import type { Config, ConfigCatUser } from "helpers/config";
import { ProjectIdCustomKey } from "helpers/config";
import { useMemo } from "react";

import { useProjectId } from "./Network/useProjectId";
import { useConfigInternal } from "./useConfigNoUser";

interface ConfigValue<T extends keyof Config> {
  setting: SettingTypeOf<Config[T]> | undefined;
  loading: boolean;
}

export function useConfig<T extends keyof Config>(key: T, defaultValue?: Config[T]): ConfigValue<T> {
  const user = useMsal();
  const projectId = useProjectId();

  const configCatUser: ConfigCatUser = useMemo(
    () => ({
      identifier: user.accounts[0]?.username,
      email: user.accounts[0]?.username,
      custom: { [ProjectIdCustomKey]: projectId },
    }),
    [projectId, user.accounts],
  );

  return useConfigInternal(key, defaultValue, configCatUser);
}
