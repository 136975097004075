import type { AdminTicketActivityDto, AdminTicketCommentDto, AdminTicketDetailsDto, UserDto } from "api/types";
import { Button } from "components/Button/Button";
import type { BaseCommentFieldProps } from "components/CommentField/CommentField";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { LoadingIcon } from "components/Icons/Icons";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Capture2, Headline4 } from "components/Text/Text";
import { TicketCommentField } from "components/Ticket/TicketCommentField";
import { parseISO } from "date-fns";
import { daysBetween } from "helpers/date";
import { TicketActivitiesSection } from "modules/tickets/components/TicketActivitySection";
import { useState } from "react";
import { BarChart as BarChartIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface CommentPayload {
  content: string;
  image?: FormImage;
  document?: FormDocument;
}

interface TicketLogProps {
  ticket: AdminTicketDetailsDto;
  sessionUser: UserDto;
  activities: AdminTicketActivityDto[];
  isFetchingMoreActivities: boolean;
  hasMoreActivities: boolean;
  fetchMoreActivities: () => void;
  isActivitiesDescending: boolean;
  onToggleActivitySorting: () => void;
  askStatusChangeOrReply: (comment: CommentPayload & { internal?: boolean }) => Promise<unknown>;
  editComment: ({ commentId, content, image, document }: CommentPayload & { commentId: string }) => Promise<void>;
  scrollTargetRef: React.RefObject<HTMLDivElement>;
}

export function TicketLog({
  ticket,
  sessionUser,
  activities,
  isFetchingMoreActivities,
  hasMoreActivities,
  fetchMoreActivities,
  isActivitiesDescending,
  onToggleActivitySorting,
  askStatusChangeOrReply,
  editComment,
  scrollTargetRef,
}: TicketLogProps): React.ReactNode {
  const { t, i18n } = useTranslation();
  const [editingComment, setEditingComment] = useState<AdminTicketCommentDto | undefined>(undefined);

  const allowedCommentAttachments: BaseCommentFieldProps["allowedAttachments"] = ["image"];
  if (sessionUser.isAdmin) {
    allowedCommentAttachments.push("document");
  }

  const displayedActivities = isActivitiesDescending
    ? [
        ...activities,
        {
          id: "activity_created",
          postedAt: ticket.createdAt,
          author: ticket.createdBy,
          created: ticket.createdAt,
        },
      ]
    : [
        {
          id: "activity_created",
          postedAt: ticket.createdAt,
          author: ticket.createdBy,
          created: ticket.createdAt,
        },
        ...activities,
      ];

  return (
    <div className="rounded-lg bg-white p-4">
      <div className="flex items-center justify-between">
        <div className="flex w-full flex-col gap-2">
          <div className="flex justify-between">
            <Headline4>{t("page.tickets.details.activities.title")}</Headline4>
            <Button
              styling="tertiary"
              onClick={onToggleActivitySorting}
              icon={
                isActivitiesDescending ? (
                  <BarChartIcon className="block shrink-0 rotate-90" size={16} />
                ) : (
                  <BarChartIcon className="block shrink-0 -rotate-90" size={16} />
                )
              }
              iconPosition="right"
            >
              {isActivitiesDescending
                ? t("page.tickets.details.activities.sort-descending")
                : t("page.tickets.details.activities.sort-ascending")}
            </Button>
          </div>
          {ticket.lastActivityBy ? (
            <Capture2 className="text-grey-darkest">
              {t("page.tickets.details.activities.subtitle", {
                time: formatDistance(i18n, { start: parseISO(ticket.lastActivityAt) }),
                name: ticket.lastActivityBy.fullName,
              })}
            </Capture2>
          ) : null}
        </div>
      </div>
      <div className={twJoin("flex flex-col pt-2", isActivitiesDescending ? "mt-4 gap-2" : "flex-col-reverse gap-4")}>
        <TicketCommentField
          canAddInternalNote={ticket.canAddInternalNote}
          canAddPublicComment={ticket.canAddPublicComment}
          onComment={askStatusChangeOrReply}
          onEditComment={editComment}
          onCancelEditComment={() => setEditingComment(undefined)}
          user={sessionUser}
          ticketId={ticket.id}
          editingComment={editingComment}
          allowedAttachments={allowedCommentAttachments}
          showCopilot
          autoFocus
        />
        <div className="max-h-96 overflow-y-auto rounded-lg border border-grey-lightest p-2">
          <TicketActivitiesSection
            ticketId={ticket.id}
            daysOpen={ticket.closedAt ? daysBetween(ticket.createdAt, ticket.closedAt) : undefined}
            forResident={false}
            activities={displayedActivities}
            onNoteEdit={(comment) => setEditingComment(comment)}
            isDescending={isActivitiesDescending}
            scrollTarget={<div ref={scrollTargetRef} />}
          />
          {hasMoreActivities ? (
            <div className="flex w-full items-center justify-center p-4">
              {isFetchingMoreActivities ? (
                <LoadingIcon className="inset-0 my-4 w-6" />
              ) : (
                <Button styling="tertiary" onClick={() => fetchMoreActivities()}>
                  {t("page.tickets.details.activities.load-more")}
                </Button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
